import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../Layout/Layout';
import ItemList from '../../ItemList/ItemList';
import useSiteMetadata from '../../../utils/useSiteMetadata';
import TagsSection from '../../TagsSection/TagsSection';
import * as styles from '../../ItemList/itemList.module.css';
import * as projectsStyles from './Projects.module.css';

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    year: string;
    tags?: {
      name: string;
      slug: string;
    }[];
    icon?: string;
    banner?: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
    pic?: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
      extension;
      publicURL;
    };
  }[];
  postsByYear: any;
  totalCount: number;
};

const Projects = ({ posts, postsByYear, totalCount }: PostsProps) => {
  const { tagsPath, basePath } = useSiteMetadata();

  return (
    <Layout
      currentPage={{
        name: 'projects',
        title: 'Projects',
        footer: 'WebView HTML',
      }}
    >
      <div className={`${styles.listHeader} ${projectsStyles.projectsHeader}`}>
        <div className={`${styles.listTitle} ${projectsStyles.projectsTitle}`}>
          <div className={styles.listTitleText}>
            <span>All</span> projects ({totalCount})
          </div>
        </div>
        <TagsSection currentTagName='All' />
      </div>
      <ItemList postsByYear={postsByYear} posts={posts} />
    </Layout>
  );
};

export default Projects;
