export default (group, postsByYear = {}) => {
  group.forEach((g) => {
    g.nodes.forEach((n) => {
      if (!postsByYear[n.year]) {
        postsByYear[n.year] = [n];
      } else {
        postsByYear[n.year].push(n);
      }
    });
  });

  return postsByYear;
};
