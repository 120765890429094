import React from 'react';
import { graphql } from 'gatsby';
import Projects from '../components/Views/Projects/Projects';
import getPostsByYear from '../utils/getPostsByYear';

type Props = {
  data: {
    allPost: any;
    [key: string]: string;
  };
};

export const query = graphql`
  query {
    allPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        tagLine
        year
        date(formatString: "DD.MM.YYYY")
        tags {
          name
          slug
        }
        banner {
          childImageSharp {
            resize {
              src
            }
          }
        }
        icon
      }
      group(field: year) {
        nodes {
          slug
          title
          tagLine
          year
          date(formatString: "DD.MM.YYYY")
          tags {
            name
            slug
          }
          pic {
            childImageSharp {
              fixed(width: 348, height: 208) {
                src
              }
              fluid(maxWidth: 348) {
                src
              }
              resize(width: 348, height: 208, quality: 90) {
                aspectRatio
                src
              }
            }
            extension
            publicURL
          }
          icon
        }
      }
      totalCount
    }
  }
`;

export default ({ data }: Props) => {
  const { allPost } = data;
  const {
    allPost: { group },
  } = data;
  const postsByYear = getPostsByYear(group);

  return (
    <Projects
      totalCount={allPost.totalCount}
      postsByYear={postsByYear}
      posts={allPost.nodes}
    />
  );
};
